<template>
    <div>
        <!-- Filters -->
        <!-- <users-list-filters
        :sort-by-filter.sync="sortByFilter"
        :sort-direction-filter.sync="sortDirectionFilter"
        :sort-by-options="sortByOptions"
        :sort-direction-options="sortDirectionOptions"
      /> -->

        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>{{ $t("Mostrar") }}</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="pageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
                        <label>{{ $t("entradas") }}</label>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1"
                                :placeholder="this.$i18n.t('Buscar...')" />
                            <!-- <b-button variant="primary" @click="() => (openModalDeposit = true)">
                  <span class="text-nowrap">{{
                    $t("Adicionar / Remover saldo")
                  }}</span>
                </b-button> -->
                        </div>
                    </b-col>
                </b-row>
            </div>

            <b-table class="position-relative" striped responsive :items="items" :fields="fields" show-empty
                :empty-text="this.$i18n.t('Nenhum registro correspondente encontrado')">
                <template #cell(user)="data">
                    <b-media vertical-align="center">
                        <template #aside>
                            <b-avatar size="32" :src="renderImgAvatar(data.item.idUser.avatar)"
                                :text="avatarText(data.item.idUser.fullname)" />
                        </template>
                        <b-link :to="{
                            name: `${routerUserView}`,
                            params: { id: data.item.idUser._id },
                        }" class="font-weight-bold d-block limitString" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary">
                            {{ data.item.idUser.fullname }}
                        </b-link>
                        <small class="text-muted">{{ data.item.idUser.email }}</small>
                    </b-media>
                </template>

                <template #cell(points)="data">
                    <div class="text-nowrap">
                        <span class="align-text-top">{{
                            parseInt(data.item.points).toLocaleString() + ' pts'
                        }}</span>
                    </div>
                </template>

                <template #cell(currentGraduation)="data">
                    <div class="text-nowrap">
                        <span class="align-text-top">{{
                            data.item.graduation
                        }}</span>
                    </div>
                </template>

                <template #cell(nextGraduation)="data">
                    <div class="text-nowrap">
                        <span class="align-text-top">{{
                            data.item.nextGraduation
                        }}</span>
                    </div>
                </template>

                <template #cell(completed)="data">
                    <div class="text-nowrap">
                        <span class="align-text-top">{{
                            parseFloat(data.item.percentageCompleted).toFixed(2) + '%'
                        }}</span>
                    </div>
                </template>

                <template #cell(createdAt)="data">
                    <div class="text-nowrap">
                        <span class="align-text-top">{{
                            format(new Date(data.item.createdAt), "dd/MM/yyyy")
                        }}</span>
                    </div>
                </template>

            </b-table>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6" class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              ">
                        <span class="text-muted">{{ $t("Mostrando") }} {{ entriesFrom }} {{ $t("a") }}
                            {{ entriesTo }} {{ $t("de") }} {{ totalRows }}
                            {{ $t("entradas") }}</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              ">
                        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>

        <b-modal id="modal-add-remove-balance" :title="userToAddRemoveBalance.fullname" hide-footer no-close-on-backdrop
            centered>
            <div class="mb-1">{{ $t("Saldo atual") }}:
                <span class="text-nowrap">{{ moneyFormat(userToAddRemoveBalance.balance) }}</span>
            </div>
            <div class="mb-1">{{ $t("Informe o valor") }}:</div>
            <b-form-input v-model="valueToAddRemoveBalance" type="number" :placeholder="$t('Valor')" />
            <small v-if="valueToAddRemoveBalance <= 0" class="text-warning mb-1">Informe um valor maior que zero</small>
            <div class="mt-1 mb-1">{{ $t("O que deseja fazer?") }}</div>
            <div class="d-flex justify-content-between">
                <b-button variant="success" :disabled="valueToAddRemoveBalance <= 0"
                    @click="() => addRemoveBalance(userToAddRemoveBalance, valueToAddRemoveBalance, 'add')">Adicionar
                    saldo</b-button>
                <b-button variant="danger" :disabled="valueToAddRemoveBalance <= 0"
                    @click="() => addRemoveBalance(userToAddRemoveBalance, valueToAddRemoveBalance, 'remove')">Remover
                    saldo</b-button>
            </div>
        </b-modal>

    </div>
</template>
  
<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormTextarea,
    VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import { mapGetters, mapActions } from "vuex";
import { format } from "date-fns";
import { debounce } from "debounce";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, min, max } from "@/libs/validations";
import Ripple from "vue-ripple-directive";

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BModal,
        BFormTextarea,
        ValidationProvider,
        ValidationObserver,

        localize,

        vSelect,
    },
    directives: {
        "b-tooltip": VBTooltip,
        Ripple,
    },
    data() {
        return {
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            totalRows: 0,
            currentPage: 1,
            entriesTo: 0,
            entriesFrom: 0,
            searchQuery: "",
            API_URL: process.env.VUE_APP_API_URL,
            usernameToDisable: "",
            reasonDisable: "",
            userToAddRemoveBalance: {},
            valueToAddRemoveBalance: 0,
            required,
            min,
            max,

            fields: [
                { key: "user", label: null },
                { key: "points", label: null },
                { key: "currentGraduation", label: null },
                { key: "nextGraduation", label: null },
                { key: "completed", label: null },
                { key: "createdAt", label: null },
            ],
            items: [],
            params: {
                page: this.currentPage,
                limit: this.perPage,
                search: "",
                sortby: "createdAt",
                sortdirection: "desc",
            },
            sortByFilter: null,
            sortByOptions: [],
            sortDirectionFilter: null,
            sortDirectionOptions: [],
        };
    },
    computed: {
        ...mapGetters(["returnLocale", "returnGraduationsList"]),
        routerUserView() {
            return "";
        },
    },
    mounted() {
        this.renderUsersList();

        this.fields[0].label = this.$i18n.t("Usuário");
        this.fields[1].label = this.$i18n.t("Pontos");
        this.fields[2].label = this.$i18n.t("Graduação atual");
        this.fields[3].label = this.$i18n.t("Próxima graduação");
        this.fields[4].label = this.$i18n.t("% Completado");
        this.fields[5].label = this.$i18n.t("Data de cadastro");

        this.sortByOptions = [
            { label: this.$i18n.t("Data de cadastro"), value: "createdAt" },
            { label: this.$i18n.t("E-mail"), value: "email" },
            { label: this.$i18n.t("Nome completo"), value: "fullname" },
            { label: this.$i18n.t("Saldo"), value: "balance" },
            { label: this.$i18n.t("Status"), value: "status" },
            { label: this.$i18n.t("Usuário"), value: "username" },
        ];

        this.sortDirectionOptions = [
            { label: this.$i18n.t("Crescente"), value: "asc" },
            { label: this.$i18n.t("Decrescente"), value: "desc" },
        ];
    },
    watch: {
        returnLocale: function (oldVal, newVal) {
            this.fields[0].label = this.$i18n.t("TxnID");
            this.fields[1].label = this.$i18n.t("Crypto");
            this.fields[2].label = this.$i18n.t("Valor USDT");
            this.fields[3].label = this.$i18n.t("Valor Crypto");
            this.fields[4].label = this.$i18n.t("Endereço Crypto");
            this.fields[5].label = this.$i18n.t("Status");
            this.fields[6].label = this.$i18n.t("Data");

            this.sortByOptions = [
                { label: this.$i18n.t("Data de cadastro"), value: "createdAt" },
                { label: this.$i18n.t("E-mail"), value: "email" },
                { label: this.$i18n.t("Nome completo"), value: "fullname" },
                { label: this.$i18n.t("Saldo"), value: "balance" },
                { label: this.$i18n.t("Status"), value: "status" },
                { label: this.$i18n.t("Usuário"), value: "username" },
            ];

            this.sortDirectionOptions = [
                { label: this.$i18n.t("Crescente"), value: "asc" },
                { label: this.$i18n.t("Decrescente"), value: "desc" },
            ];
        },
        returnGraduationsList: function (oldVal, newVal) {
            this.totalRows = oldVal.total;
            this.entriesTo =
                oldVal.perPage * oldVal.currentPage > oldVal.total
                    ? oldVal.total
                    : oldVal.perPage * oldVal.currentPage;
            this.entriesFrom =
                oldVal.perPage * oldVal.currentPage + 1 - oldVal.perPage;
            this.items = oldVal.graduationsList;
        },
        perPage: function (newVal) {
            this.params.limit = newVal;
            this.renderUsersList();
        },
        currentPage: function (newVal) {
            this.params.page = newVal;
            this.renderUsersList();
        },
        searchQuery: debounce(function (newVal) {
            this.params.search = newVal;
            this.renderUsersList();
        }, 500),
        statusFilter: function (newVal) {
            this.params.status = newVal;
            this.renderUsersList();
        },
        sortByFilter: function (newVal) {
            this.params.sortby = newVal;
            this.renderUsersList();
        },
        sortDirectionFilter: function (newVal) {
            this.params.sortdirection = newVal;
            this.renderUsersList();
        },
    },
    methods: {
        ...mapActions(["usersList", "sendEmailAPI", "disableUserAPI"]),
        addRemoveBalanceModal(user) {
            this.userToAddRemoveBalance = user;
            this.$bvModal.show("modal-add-remove-balance");
        },
        addRemoveBalance(user, value, action) {
            this.$store
                .dispatch("addRemoveBalance", {
                    user_id: user._id,
                    value: value,
                    action: action,
                })
                .then((resp) => {
                    this.userToAddRemoveBalance = {}
                    this.valueToAddRemoveBalance = 0
                    this.$bvModal.hide("modal-add-remove-balance");
                    this.renderUsersList();
                    this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                            icon: "CoffeeIcon",
                            variant: "success",
                            title: resp,
                        },
                    });
                })
                .catch(err => {
                    this.userToAddRemoveBalance = {}
                    this.valueToAddRemoveBalance = 0
                    this.$bvModal.hide("modal-add-remove-balance");
                    this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                            icon: "CoffeeIcon",
                            variant: "danger",
                            title: err,
                        },
                    });
                });
        },
        renderImgAvatar(img) {
            if (img)
                return img ? process.env.VUE_APP_API_URL + "/avatar/" + img : null;
        },

        userMaxRole(array) {
            let filtered = array.map((res) => res.role_id);
            return array.find((res) => res.role_id == Math.min(...filtered)).name;
        },

        renderUsersList() {
            this.$store.dispatch("fetchGraduations", this.params).then((resp) => {
                /** */
            });
        },

        sendEmail() {
            this.sendEmailAPI(this.usernameToDisable.id).then((response) => {
                this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                        icon: "CoffeeIcon",
                        variant: "success",
                        title: this.$i18n.t("E-mail enviado!"),
                        text: this.$i18n.t(
                            "O e-mail de convite foi reenviado com sucesso."
                        ),
                    },
                });
            });
        },
    },
    setup() {
        format;

        const isAddNewUserSidebarActive = ref(false);

        const resolveUserRoleVariant = (role) => {
            if (role === "customer") return "primary";
            if (role === "employee_store") return "warning";
            if (role === "seller") return "success";
            if (role === "employee_adm") return "info";
            if (role === "administrator") return "danger";
            return "primary";
        };

        const resolveUserRoleIcon = (role) => {
            if (role === "customer") return "UserIcon";
            if (role === "employee_store") return "SettingsIcon";
            if (role === "seller") return "DatabaseIcon";
            if (role === "employee_adm") return "Edit2Icon";
            if (role === "administrator") return "ServerIcon";
            return "UserIcon";
        };

        const resolveUserStatusVariant = (status) => {
            if (status === "pending") return "warning";
            if (status === "active") return "success";
            if (status === "inactive") return "secondary";
            return "primary";
        };

        return {
            // Sidebar
            isAddNewUserSidebarActive,

            // Utilis
            avatarText,
            format,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,
        };
    },
};
</script>
  
<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>
  
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.limitString {
    white-space: nowrap;
    max-width: 10em;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
  